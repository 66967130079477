<mat-form-field appearance="outline" [ngClass]="{'mat-table-form-field-inline-text': inline}">
  <div class="select-container">
    <ng-container *ngIf="!control.value.length">
      <span class="trigger">{{label}} Filter</span>
    </ng-container>
    <div [ngClass]="{'empty-height': (control.value?.length || 0) === 0}" class="hide-select-arrow">
      <mat-select [formControl]="control" multiple #matSelect>
        <mat-select-trigger *ngIf="useMoreLabel || allSelectedLabel">
          <ng-container *ngIf="allSelectedLabel && allOptionsSelected">
            <span class="trigger">
              {{allSelectedLabel}}
            </span>
          </ng-container>
          <ng-container *ngIf="useMoreLabel && !allOptionsSelected">
            <span *ngIf="(control.value?.length || 0) === 1" class="trigger">
              {{getSelectedOptionLabel(control.value[0]) || ''}}
            </span>
            <span *ngIf="(control.value?.length || 0) > 1" class="trigger">
              {{(control.value?.length || 0)}} {{label}} Selected
            </span>
          </ng-container>
          <!-- <mat-icon svgIcon="pq:chevron-down" *ngIf="inline"></mat-icon> -->
        </mat-select-trigger>
        <mat-option>
          <ngx-mat-select-search
            [formControl]="searchControl"
            [placeholderLabel]="'Search...' | translate"
            [noEntriesFoundLabel]="'No matches found' | translate"
            [showToggleAllCheckbox]="true"
            [toggleAllCheckboxChecked]="allOptionsSelected"
            [toggleAllCheckboxTooltipMessage]="'Select all' | translate"
            (toggleAll)="toggleSelectAll($event)"
          >
            <mat-icon svgIcon="pq:trash" ngxMatSelectSearchClear></mat-icon>
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let option of filteredOptions" [value]="option.value">
          <span class="option-label">{{option.label}}</span>
        </mat-option>
      </mat-select>
    </div>
  </div>
</mat-form-field>
