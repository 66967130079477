import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {inject} from '@angular/core';
import {Network} from "@capacitor/network";

export const OfflineGuard = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree> => {

  const status = await Network.getStatus();
console.log('route', route)
console.log('state', state.url)

  if (!state.url.includes('dashboard') && !status.connected) {
    const router = inject(Router);
    router.navigate(['dashboard']);
  }

  return status.connected;
};
