import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/min';

@Pipe({
    name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

    transform(phoneValue: number | string, country: string = 'US'): any {
        try {
            const phoneNumber = parsePhoneNumber(phoneValue + '', country as CountryCode);
            return phoneNumber.format(phoneNumber.countryCallingCode === '1' ? 'NATIONAL' : 'INTERNATIONAL');
        } catch (error) {
            return phoneValue;
        }
    }

}