import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccordionGroupComponent} from './components/accordion-group/accordion-group.component';
import {AccordionItemComponent} from './components/accordion-group/accordion-item/accordion-item.component';
import {AccordionItemHeaderComponent} from './components/accordion-group/accordion-item/accordion-item-header/accordion-item-header.component';
import {AccordionItemBodyComponent} from './components/accordion-group/accordion-item/accordion-item-body/accordion-item-body.component';
import {EditCircleComponent} from './components/edit-circle/edit-circle.component';
import {MatNativeDateModule} from '@angular/material/core';
import {MultipleSelectComponent} from './components/multiple-select/multiple-select.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NotFoundComponent } from './components/not-found/not-found.component';
import {IsMobileDirective} from '../is-mobile.directive';
import {TimeAgoPipe} from './pipes/time-ago.pipe';
import {MatIconModule} from '@angular/material/icon';
import {NgxPrintDirective} from './directives/ngx-print.directive';
import { TableMultipleSelectComponent } from './components/table-multiple-select/multiple-select.component';
import { TableSingleSelectComponent } from './components/table-single-select/single-select.component';
import { TableFiltersBarComponent } from './components/table-filters-bar/table-filters-bar.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from "@angular/material/sort";
import { TranslateModule } from '@ngx-translate/core';
import { MatTableResponsiveDirective } from 'src/app/shared/directives/mat-table-responsive/mat-table-responsive.directive';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { OrderByPipe } from "./pipes/order-by-pipe.pipe";
import { DateSelectorComponent } from './components/date-selector/date-selector.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import {FilterTagsPipe} from "../modules/call-tracking/numbers/tags/filter-tags.pipe";
import { LoadableDirective } from './directives/skeleton-loader/loadable.directive';
import { LoadableHostDirective } from './directives/skeleton-loader/loadable-host.directive';

@NgModule({
  declarations: [
    AccordionGroupComponent,
    AccordionItemComponent,
    AccordionItemHeaderComponent,
    AccordionItemBodyComponent,
    DateSelectorComponent,
    EditCircleComponent,
    MultipleSelectComponent,
    TableMultipleSelectComponent,
    TableSingleSelectComponent,
    TableFiltersBarComponent,
    NotFoundComponent,
    IsMobileDirective,
    TimeAgoPipe,
    OrderByPipe,
    FilterTagsPipe,
    NgxPrintDirective,
    MatTableResponsiveDirective,
    TruncatePipe,
    PhoneNumberPipe,
    LoadableDirective,
    LoadableHostDirective,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,

    MatNativeDateModule,
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatListModule,
    MatCheckboxModule,
    MatTabsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatTooltipModule,

    AccordionGroupComponent,
    AccordionItemComponent,
    AccordionItemHeaderComponent,
    AccordionItemBodyComponent,
    DateSelectorComponent,
    EditCircleComponent,
    MultipleSelectComponent,
    TableMultipleSelectComponent,
    TableSingleSelectComponent,
    TableFiltersBarComponent,
    IsMobileDirective,
    TimeAgoPipe,
    OrderByPipe,
    FilterTagsPipe,
    NgxPrintDirective,
    MatTableResponsiveDirective,
    OverlayModule,
    TranslateModule,
    TruncatePipe,
    PhoneNumberPipe,
    LoadableDirective,
    LoadableHostDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,

    MatNativeDateModule,
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatListModule,
    MatCheckboxModule,
    MatTabsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatTooltipModule,
    OverlayModule,
    TranslateModule,
  ]
})
export class SharedModule {
}
