import {Injectable} from '@angular/core';
import { JWTServiceInterface } from './JWT-service.interface';

@Injectable({
  providedIn: 'root'
})
export class JWTAndriodService implements JWTServiceInterface {
  // @ts-ignore
  isCordova = Boolean(window.cordova);
  public pqCachedToken = '';

  constructor() {
  }

  async getToken() {
    if (!this.isCordova) {
      return;
    }

    const refreshData = async () => {
      let savedJWT = await this.getJWTFromDB();
      return Promise.resolve(savedJWT);
    }

    return await refreshData();
  }

  getJWTFromDB() {
    if (!this.isCordova) {
      return;
    }
    return new Promise((resolve, reject) => {
      if(!this.pqCachedToken) {
        this.pqCachedToken = sessionStorage.getItem('token');
      }
      // console.log('get token', this.pqCachedToken)
      resolve(this.pqCachedToken);
    });
  }

  setToken(token = null, type = 'Bearer', expired = 0) {
    // console.log('set token', token)
    if (!this.isCordova) {
      // @ts-ignore
      window.cachedToken = '';
      return;
    }

    sessionStorage.setItem('token', token);
    this.pqCachedToken = token;
  }

  removeToken() {
    sessionStorage.removeItem('token');
    this.pqCachedToken = '';
  }

}
