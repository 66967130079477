<app-manager-navbar
  [hidden]="isActive()"
  *ngIf="authUser && (authUser.permissions === roles.ADMIN || authUser.permissions === roles.MANAGER || authUser.permissions === roles.JUNIOR_MANAGER)"
  [authUser]="authUser">
</app-manager-navbar>


<app-tenant-navbar
  *ngIf="authUser && authUser.permissions !== roles.ADMIN && authUser.permissions !== roles.MANAGER && authUser.permissions !== roles.JUNIOR_MANAGER"
  [authUser]="authUser">
</app-tenant-navbar>
