import {Tenant} from './tenant';

export interface User {
  documents: any;
  id: number;
  user_id: number;
  company_id: number | null;
  first_name: string;
  last_name: string;
  email: string;
  permissions: 'payquad_admin' | 'admin_manager' | 'junior_manager' | 'applicant' | 'tenant' | 'realtor';
  junior_permissions: 'full' | 'read-only' | 'maintenance-requests';
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
  lease_rent_editable: boolean;
  lease_rent_editable_type: 'both' | 'up' | 'down';
  insurance: boolean;
  global_filters: Array<{
    key: string;
    value: string | Array<any>;
  }>;
  insurance_nav: boolean;
  rewards: boolean;
  tenants_can_message: boolean;
  pro_messaging: boolean;
  pro_message_alert_sent: boolean;
  property_perks: boolean;
  maintenance_requests_pro: boolean;
  maintenance_request_emergency_message: string;
  show_maintenance_request_custom_message: boolean;
  suspended: boolean | number;
  html_version: string;
  has_angular_messaging_access?: boolean;
  parcels_signing_enabled: boolean;
  login_as?: number;
  linked_accounts: Array<{
    id: number;
    email: string;
    tenant: Tenant
  }>;
  user_permissions: Record<UserPermissions, boolean>;
  locale?: string;
  new_dash_acknowledged?: boolean;
  signature: string;
  company_subdomain: string | null;
}


export enum UserPermissions {
  Billing = 'billing',
  Amenities = 'amenities',
  Communications = 'communications',
  Documents = 'documents',
  Inspections = 'inspections',
  LeaseApplications = 'lease-applications',
  MaintenanceRequests = 'maintenance-requests',
  Parcels = 'parcels',
  Payments = 'payments',
  Properties = 'properties',
  ProspectEvents = 'prospect-events',
  Reports = 'reports',
  Residents = 'residents',
  Surveys = 'surveys',
  TicketsAccess = 'tickets-access'
}

