import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../shared/interfaces/user';
import {APP_USER_ROLES, APP_USER_STATUS} from '../../shared/constants';
import {Store} from '@ngrx/store';
import {selectAppUser} from '../../store/app.selectors';
import {State} from '../../store/app.state';
import {UnsubscribableComponent} from 'src/app/shared/classes/abstract/unsubscribable-component';
import {Router} from "@angular/router";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent extends UnsubscribableComponent implements OnInit, OnDestroy {

  public authUser: User | null;
  public roles = APP_USER_ROLES;

  constructor(private store: Store<State>, public router: Router) {
    super();
  }

  ngOnInit() {
    this.sub = this.store.select(selectAppUser).subscribe(user => this.authUser = user !== APP_USER_STATUS.NOT_AUTHORIZED ? user : null);
  }

  ngOnDestroy() {
    this.unsubscribeAll();

  }

  isActive(): boolean {
    return this.router.isActive('/n/2fa', true);
  }

}
