import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, filter, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { State } from '../../store/app.state';
import { User } from '../interfaces/user';
import { selectAppUser } from '../../store/app.selectors';
import { loadUser } from '../../store/app.actions';
import { APP_USER_STATUS } from '../constants';
import { inject } from '@angular/core';

export const AuthGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const store: Store<State> = inject(Store<State>);

  const getUserFromStateOrAPI = (): Observable<User | null | APP_USER_STATUS.NOT_AUTHORIZED> => {
    return store.pipe(
      select(selectAppUser),
      tap((user: User | null | APP_USER_STATUS.NOT_AUTHORIZED) => {
        if (user === null) {
          store.dispatch(loadUser());
        }
      }),
      filter((user: User | null | APP_USER_STATUS.NOT_AUTHORIZED) => user !== null),
      take(1)
    );
  };

  return getUserFromStateOrAPI().pipe(
    exhaustMap((u) => {
      if (u === null || u === APP_USER_STATUS.NOT_AUTHORIZED) {
        window.location.href = '/#/n/login';
        return of(false);
      }
      return of(true);
    }),
    catchError(() => of(false))
  );
};
