import {Component, HostBinding, Inject, Input, NgModule, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../shared/interfaces/user';
import {Branding} from '../../../shared/interfaces/branding';
import {Store} from '@ngrx/store';
import {State} from '../../../store/app.state';
import {selectAppBranding, selectAppLocale} from '../../../store/app.selectors';
import {changeUser, loadTranslations, logout, setLocale} from '../../../store/app.actions';
import {LangService} from '../../../shared/services/lang.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {UnsubscribableComponent} from '../../../shared/classes/abstract/unsubscribable-component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import { MobileHelper } from 'src/app/shared/helpers/mobile-helper';

@Component({
  selector: 'app-tenant-navbar',
  templateUrl: './tenant-navbar.component.html',
  styleUrls: ['./tenant-navbar.component.scss']
})
export class TenantNavbarComponent extends UnsubscribableComponent implements OnInit, OnDestroy {

  @Input()
  public authUser: User;
  public activeInMobile: any[];
  public mobileMenus = {};
  public isMobile = true;
  public isCordova = true;

  @HostBinding('style.--primary-color') get primaryColour() {
    return this.branding.primary_colour;
  }

  @HostBinding('style.--secondary-color') get secondaryColour() {
    return this.branding.secondary_colour;
  }

  public branding: Branding;
  public localeButtonString = 'En';
  public t: Function;
  public brandingCssUrl: SafeUrl;


  constructor(
    private store: Store<State>,
    private langService: LangService,
    private sanitizer: DomSanitizer,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private mobileHelper: MobileHelper,

  ) {
    super();
    this.t = langService.t('navbar');
    this.brandingCssUrl = this.sanitizer.bypassSecurityTrustUrl('');
  }

  ngOnInit() {
    this.sub = this.store.select(selectAppBranding).subscribe(branding => {
      this.branding = branding;
      if (branding.css_file_url) {
        this.brandingCssUrl = this.sanitizer.bypassSecurityTrustResourceUrl(branding.css_file_url);
      }
    });
    this.sub = this.store.select(selectAppLocale).subscribe(locale => this.localeButtonString = locale);

    // @ts-ignore
    this.isCordova = Boolean(window.cordova);

    this.isMobile = this.mobileHelper.isMobileOrTablet();

    this.mobileMenus =  {
      payments: {
        isActive: this.authUser.user_permissions.payments,
        menuNumber: 0
      },
      maintenance: {
        isActive: this.authUser.user_permissions['maintenance-requests'],
        menuNumber: 1
      },
      amenities: {
        isActive: this.authUser.user_permissions.amenities,
        menuNumber: 2
      },
      communication: {
        isActive: this.authUser.user_permissions.communications,
        menuNumber: 3
      },
      paymentHistory: {
        isActive: this.authUser.user_permissions.payments,
        menuNumber: 4
      },
      parcels: {
        isActive: this.authUser.user_permissions.parcels,
        menuNumber: 5
      },
      documents: {
        isActive: this.authUser.user_permissions.documents,
        menuNumber: 6
      },
      insurance: {
        isActive: this.authUser.insurance_nav,
        menuNumber: 7
      },
      rewards: {
        isActive: this.authUser.rewards,
        menuNumber: 8
      }
    };

    this.activeInMobile = [];

    const obj = this.mobileMenus;

    for (const key in obj) {
      if (obj[key].isActive) {
        this.activeInMobile.push(obj[key].menuNumber);
      }
    }

    // tslint:disable-next-line:only-arrow-functions
    this.activeInMobile = this.activeInMobile.sort(function(a, b) {
      return a - b;
    });

    const numberOfMenuItemsToShow = 4;

    this.activeInMobile = this.activeInMobile.slice(0, numberOfMenuItemsToShow);
  }

  public linkStyle(): object {
    return {
      color: window.innerWidth > 768 ? this.branding.secondary_colour : this.branding.primary_colour
    };
  }

  public isMenuItemActiveInMobile(menuNumber) {

    if (!this.isMobile) {
      // return false;
    }
    return !this.activeInMobile.includes(menuNumber);
  }

  public signOut(): void {
    if(this.authUser.login_as){
      window.location.href = '/login-as/leave';
      return;
    }
    this.store.dispatch(logout());
  }

  public setLocalization(locale: 'en' | 'fr'): void {
    this.store.dispatch(setLocale({locale}));
    this.store.dispatch(loadTranslations({locale}));

    console.log('setLocalization');
    this._locale = 'ja-JP';
    this._adapter.setLocale(this._locale);

  }

  public changeUser(userId: number) {
    this.store.dispatch(changeUser({id: userId}));
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

}
