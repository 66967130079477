import {User} from '../shared/interfaces/user';
import {Branding} from '../shared/interfaces/branding';
import {CompaniesState} from '../modules/companies/store/companies.state';
import {LeaseApplicationsState} from '../modules/lease-applications/store/lease-applications.state';
import {MessagesState} from '../modules/messages/store/messages.state';
import {APP_USER_STATUS} from '../shared/constants';
import { ManagersState } from '../modules/managers/store/managers.state';
import { PropertiesState } from '../modules/properties/store/properties.state';
import {DashboardState} from '../modules/dashboard/store/dashboard.state'

export interface AppState {
  user: null | User | APP_USER_STATUS.NOT_AUTHORIZED;
  branding: Branding;
  currentPage: string;
  translations: any;
  locale: string;
}

export const initialAppState: AppState = {
  user: null,
  branding: {
    company_id: -1,
    name: '',
    logo_file_url: '',
    nav_logo_file_url: '',
    background_file_url: '',
    address: '',
    phone_number: '',
    email: '',
    website: null,
    twitter: null,
    facebook: null,
    welcome_message: '',
    primary_colour: '#008b95',
    secondary_colour: '#F2F2F2',
    android_application_link: '',
    ios_application_link: ''
  },
  currentPage: 'Dashboard',
  translations: {},
  locale: 'en',
};

export interface State {
  app: AppState;
  companies: CompaniesState;
  managers: ManagersState;
  properties: PropertiesState,
  'lease-applications': LeaseApplicationsState;
  messages: MessagesState;
  dashboard: DashboardState;
}
