import {Component, OnDestroy, OnInit} from '@angular/core';
import {Branding} from '../../shared/interfaces/branding';
import {Store} from '@ngrx/store';
import {State} from '../../store/app.state';
import {selectAppBranding, selectAppLocale} from '../../store/app.selectors';
import {UnsubscribableComponent} from '../../shared/classes/abstract/unsubscribable-component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends UnsubscribableComponent implements OnInit, OnDestroy {

  public branding: Branding;

  public lang = {
    terms: 'Terms of Service',
    privacy: 'Privacy Policy'
  };

  constructor(private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.sub = this.store.select(selectAppBranding).subscribe(branding => this.branding = branding);
    this.sub = this.store.select(selectAppLocale).subscribe(locale => {
      if (locale === 'en') {
        this.lang = {
          terms: 'Terms of Service',
          privacy: 'Privacy Policy'
        };
      } else {
        this.lang = {
          terms: 'Conditions d\'Utilisation',
          privacy: 'Politique de confidentialité'
        };
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }
}
