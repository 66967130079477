import { Directive, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Directive({
  selector: "[loadable]",
})
export class LoadableDirective implements OnInit, OnDestroy {

  public resolved = new BehaviorSubject(false);
  @HostBinding("class") elementClass = "loadable";

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy() {}
}
