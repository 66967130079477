import {Injectable} from '@angular/core';
import { JWTServiceInterface } from './JWT-service.interface';

@Injectable({
  providedIn: 'root'
})
export class JWTWebService implements JWTServiceInterface{
  constructor() {
  }

  async getToken() {
    return;
  }

  setToken(token = null, type = 'Bearer', expired = 0) {
    // @ts-ignore
    window.cachedToken = '';
    return;
  }

  removeToken() {
    return;
  }
}
