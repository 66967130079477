import {Injectable, NgZone} from '@angular/core';
import {ANGULAR_JS_EVENTS} from '../constants';

@Injectable({
  providedIn: 'root'
})
export class AngularJsService {

  allowedEvents = ANGULAR_JS_EVENTS;

  private subscriptions: { [key: string]: Function[]; } = {};

  constructor(private zone: NgZone) {
    Object.values(this.allowedEvents).forEach((eventName) => {
      this.subscriptions[eventName] = [];
    });

    window['fireAngularEvent'] = (eventName: string, args: any) => {
      if (!this.subscriptions[eventName]) {
        throw new Error('Event has to be defined in the event list.');
      }

      zone.run(() => {
        this.fireEvent(eventName, args);
      });
    };

    window['subscribeToAngularEvent'] = (eventName, fn) => {
      this.subscribe(eventName, fn);
    };
  }

  subscribe(eventName: string, fn: Function) {
    if (!this.subscriptions[eventName]) {
      throw new Error('Event has to be defined in the event list.');
    }

    this.subscriptions[eventName].push(fn);
  }

  fireEvent(eventName: string, args?: any) {
    if (!this.subscriptions[eventName]) {
      throw new Error('Event has to be defined in the event list.');
    }

    this.subscriptions[eventName].forEach(fn => {
      fn.apply(null, [args]);
    });
  }
}
