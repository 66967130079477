import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  DoCheck,
  SimpleChanges,
  IterableDiffer,
  IterableDiffers,
  IterableChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {SelectOption} from '../../interfaces/select-option';
import {UnsubscribableComponent} from '../../classes/abstract/unsubscribable-component';
import {MatSelect} from '@angular/material/select';

/**
 * Reusable Multiselection filter for filter bar in index pages 
 */
@Component({
  selector: 'table-multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss']
})
export class TableMultipleSelectComponent extends UnsubscribableComponent implements OnInit, OnChanges, OnDestroy, DoCheck {
  @ViewChild('matSelect') ref: MatSelect;

  @Input() control: FormControl;
  @Input() options: any[];
  @Input() label: string;
  @Input() transpiler: (item: any) => SelectOption;

  @Input() useMoreLabel = false;
  @Input() allSelectedLabel = '';

  @Input() inline = false;

  noneSelected = true;

  public transpilingOptions: Array<SelectOption> = [];
  public filteredOptions: Array<SelectOption> = [];
  public searchControl: FormControl = new FormControl('');
  get allOptionsSelected(): boolean {
    return this.control.value.length === this.options.length;
  }

  private _diff: IterableDiffer<number>;

  constructor(
    private _iterableDiffers: IterableDiffers,
  ) {
    super();
    // console.log(this.control)
  }

  // Trigger filtering method and populate data
  ngOnInit(): void {
    this.sub = this.searchControl.valueChanges
      .subscribe(() => {
        this.filterItems();
      });

    this._diff = this._iterableDiffers.find(this.options).create();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngDoCheck(): void {
    //use instead of ngOnChanges for when array is mutated.
    const changes: IterableChanges<number> = this._diff.diff(this.options); //check if new options have been added

    if (changes) {
      // console.log("options", this.options)
      // console.log("transpiler", this.transpiler)
      this.transpilingOptions = this.options.map(this.transpiler);
      this.filterItems();
    }
  }

  toggleSelectAll(selectAllValue: boolean) {
    if (selectAllValue) {
      this.control.patchValue(this.filteredOptions.map(item => item.value));
    } else {
      this.control.patchValue([]);
    }
  }

  getSelectedOptionLabel(value: any): string {
    if (value) {
      return this.transpilingOptions.find(o => o.value.toString() === value.toString())?.label;
    }
    return '';
  }

  protected filterItems() {
    if (!this.transpilingOptions.length) {
      return;
    }
    let search = this.searchControl.value;
    if (!search) {
      this.filteredOptions = this.transpilingOptions.slice();
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredOptions = this.transpilingOptions.filter(item => item.label.toLowerCase().indexOf(search) > -1);
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

}
