import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../../store/app.state";
import {LoginService} from "../../login/login.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {loadUser, logout, setUser} from "../../../store/app.actions";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {Branding} from "../../../shared/interfaces/branding";
import {selectAppBranding, selectAppUser} from "../../../store/app.selectors";
import {UnsubscribableComponent} from "../../../shared/classes/abstract/unsubscribable-component";
import {take} from "rxjs/operators";
import {APP_USER_STATUS} from "../../../shared/constants";

declare var angular;

@Component({
  selector: 'app-set-up',
  templateUrl: './set-up.component.html',
  styleUrls: ['./set-up.component.scss']
})
export class SetUpComponent extends UnsubscribableComponent implements AfterViewInit {
  @Output() twoFaLogOut = new EventEmitter<string>();
  @Input() isEmbedded: boolean = false;
  imagePath: SafeResourceUrl;
  private optQR: any;
  code: string = '';
  public branding: Branding;
  isLoading: boolean = false;

  constructor(private store: Store<State>,
              private _sanitizer: DomSanitizer,
              public loginService: LoginService,
              private toastr: ToastrService,
              private router: Router,
  ) {
    super();
    this.sub = this.store.select(selectAppBranding).subscribe(branding => this.branding = branding);
  }

  logout() {
    this.store.dispatch(logout());
    this.twoFaLogOut.emit();
  }

  ngAfterViewInit(): void {
    this.loginService.getOptQR().subscribe(data => {
        if (data) {
          this.optQR = data;
        }
      },
      error => {
        this.optQR = error.error.text;
        this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl(this.optQR);
      })
  }

  enable() {
    if (this.code.length !== 6) {
      this.toastr.clear()
      this.toastr.error('Verification Code is Incorrect');
      return;
    }
    this.isLoading = true;

    this.loginService.enable(this.code).subscribe((resp) => {
      const data = resp.data;
      if (data && data.success) {
        this.toastr.success('Time-based One-time Password Enabled');

        this.store.dispatch(loadUser());

        this.store.select(selectAppUser).pipe(
          take(2)
        ).subscribe((user) => {
          if (user && user !== APP_USER_STATUS.NOT_AUTHORIZED) {
            const updatedUser = { ...user, '2_fa_install_required': false };
            this.store.dispatch(setUser({ user: updatedUser }));
            this.router.navigateByUrl('/n/dashboard').then(r => {
              const angularJsScope = angular.element(document.getElementById('angularjs-container')).scope();
              if (angularJsScope) {
                angularJsScope.$state.go('main.ng2', {}, { notify: false });
              } else {
                console.error('Where is angular scope???', angularJsScope);
              }
            });
          } else {
            console.error('User is not authorized or null');
          }
        });
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.toastr.error('Verification Code is Incorrect');
      }
    }, function () {
      this.toastr.error('Verification Code is Incorrect');
    });

  }

}
