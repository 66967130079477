<div class="sidenav-navigation-container">
  <mat-nav-list class="side-nav-list">
    <ng-container *ngFor="let item of (menu|async)">
      <ng-template [ngTemplateOutlet]="itemTemplate"
                   [ngTemplateOutletContext]="{ $implicit: item }"
      ></ng-template>
    </ng-container>
  </mat-nav-list>
  <div class="sidenav-footer">
    <a tabindex="1"
       class="info"
       data-toggle="modal"
       data-target=".terms"
    >{{ "Terms & Conditions" | translate }}</a>
    <a tabindex="1"
       class="info"
       data-toggle="modal"
       data-target=".privacy"
    >{{ "Privacy Policy" | translate }}</a>
    <a href="https://payquad.com/2342fdsa32/" (click)="openSupport()" target="_blank"
    >{{ "Support" | translate }}</a>
  </div>
</div>


<ng-template let-item #itemTemplate>
  <ng-container *ngIf="checkAccess(item)">
    <a mat-list-item
       [routerLinkActive]="item.routerLink ? 'active' : ''"
       [ngClass]="{'expanded': item.expanded, 'has-child': item.child.length, 'is-child': !!item.parent, 'child-expanded': item.parent?.expanded, 'active': router.isActive(item.routerLink, true)}"
       [title]="item.name"
       (click)="link(item)"
    >
      <mat-icon mat-list-item-icon
                svgIcon="pq:{{item.icon}}"
                *ngIf="item.icon"
      ></mat-icon>
      <span mat-list-item-icon class="mat-icon" *ngIf="!item.icon"></span>
      <span mat-list-item-line
            class="title"
      >{{ item.name | translate }}</span>
      <div class="expand-control">
        <mat-icon svgIcon="pq:arrow-up"></mat-icon>
      </div>
    </a>
    <ng-container *ngFor="let i of item.child">
      <ng-template [ngTemplateOutlet]="itemTemplate"
                   [ngTemplateOutletContext]="{ $implicit: i }"
      ></ng-template>
    </ng-container>
  </ng-container>
</ng-template>
