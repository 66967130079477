<div class="date-selector-wrapper">
    <button mat-icon-button (click)="toggleOverlay()" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
      <mat-icon svgIcon="pq:calendar"></mat-icon>
    </button>
    <span class="select-value" (click)="toggleOverlay()">{{ (selectedDateOption | async).display_name }}</span>
    <mat-icon svgIcon="pq:chevron-down" (click)="toggleOverlay()"></mat-icon>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  (overlayOutsideClick)="isOpen = false"
  class="overlay"
>
  <div class="overlay-content custom-date-selector-overlay">
    <div class="top">
      <mat-list class="time-option-menu">
        <mat-list-item class="time-option" *ngFor="let item of timeOptions" [class.selected]="item.value === (selectedDateOption | async).value" (click)="selectTimeOption(item)">
          {{ item.display_name }}
        </mat-list-item>
      </mat-list>
  
      <mat-calendar 
        [selected]="selectedRangeValue"
        (selectedChange)="selecteDateRangeChange($event)"
        [startAt]="selectedRangeValue?.start"
        >
      </mat-calendar>
    </div>
    <div class="action-buttons">
      <div class="align-left">
        <button mat-button color="warn" (click)="clearAll()">
          {{'Clear All' | translate}}
        </button>
      </div>

      <div class="align-right">
        <button mat-stroked-button (click)="cancel()">
          {{'Cancel' | translate}}
        </button>
  
        <button mat-flat-button color="primary" (click)="applyAndClose()">
          {{'Apply' | translate}}
        </button>
      </div>
    </div>

  </div>
</ng-template>