import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../../store/app.state';
import {selectAppTranslations} from '../../store/app.selectors';
@Injectable({
  providedIn: 'root'
})
export class LangService {

  private translations: any;

  constructor(private store: Store<State>) {
    this.store.select(selectAppTranslations).subscribe(translations => this.translations = translations);
  }

  public t(prefix: string): (key: string) => string {
    return (key: string, params: object = {}) => this.trans(`${prefix}.${key}`, params);
  }

  private trans(key: string, params: object): string {
    const replace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let translation = key.split('.').reduce((t, i) => {
      return typeof t === 'undefined' || t[i] === 'undefined' ? '' : t[i];
    }, this.translations);

    for (const placeholder in replace) {
      translation = translation.replace(':'.concat(placeholder), replace[placeholder]);
    }

    return translation;
  }
}
