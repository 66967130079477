export enum APP_USER_ROLES {
  ADMIN = 'payquad_admin',
  MANAGER = 'admin_manager',
  JUNIOR_MANAGER = 'junior_manager',
  TENANT = 'tenant'
}

export enum APP_USER_STATUS {
  NOT_AUTHORIZED = 'not authorized'
}


export enum ANGULAR_JS_EVENTS {
  SIGN_OUT = 'pq.sign-out',
  SIGN_IN = 'pq.sign-in',
  SESSION_EXPIRED = 'pq.session-expired',
  USER_LOADED = 'pq.user-loaded',
  ANGULAR_JS_ROUTE_IS_OPENED = 'pq.angularjs-route',
  ANGULAR_JS_LANG_CHANGED = 'pq.angular-js-lang-changed',
  SIDENAV_TOGGLE = 'pq.angular-js-sidenav-toggle',
  NEW_MESSAGES = 'pq.angular-js-new-messages',
  LOG_IN_AFTER_RESET_PASSWORD = 'pq.log-in-after-reset-password',
  // SIDENAV_EXPAND = 'pq.angular-js-sidenav-expand',
}
