import {Component, OnDestroy, OnInit} from '@angular/core';
import {Branding} from "../../shared/interfaces/branding";
import {User} from "../../shared/interfaces/user";
import {Store} from "@ngrx/store";
import {State} from "../../store/app.state";
import {LangService} from "../../shared/services/lang.service";
import {LoginService} from "../login/login.service";
import {JWTService} from "../../shared/services/JWT.service";
import {Router} from "@angular/router";
import {UnsubscribableComponent} from "../../shared/classes/abstract/unsubscribable-component";
import {selectAppBranding, selectAppLocale} from "../../store/app.selectors";
import {ToastrService} from "ngx-toastr";
import {logout} from "../../store/app.actions";

declare var angular;

@Component({
  selector: 'app-auth2fa',
  templateUrl: './auth2fa.component.html',
  styleUrls: ['./auth2fa.component.scss']
})
export class Auth2faComponent extends UnsubscribableComponent implements OnInit, OnDestroy {
  public branding: Branding;
  public t: Function;
  public localeButtonString = 'En';
  errors: any;
  public authUser: User | null;
  public loggingIn = false;
  authData = {
    property: 1,
    email: localStorage.getItem('email'),
    password: '',
    remember_me: !!localStorage.getItem('remember_me'),
    agree_terms: false,
    phone_number: '',
    last_name: '',
    first_name: '',
    // @ts-ignore
    cordova: window.cordova ? 1 : 0
  };
  public db: any = null;
  code: string = '';
  isLoading: boolean = false;

  constructor(private store: Store<State>,
              private langService: LangService,
              protected loginService: LoginService,
              protected jwtService: JWTService,
              private router: Router,
              private toastr: ToastrService) {

    super();
    this.t = this.langService.t('auth');
  }

  ngOnInit(): void {
    this.sub = this.store.select(selectAppBranding).subscribe(branding => this.branding = branding);
    this.sub = this.store.select(selectAppLocale).subscribe(locale =>
      this.localeButtonString = locale
    );
  }

  ngOnDestroy(): void {
  }

  submit() {
    if (!this.code || this.code.length != 6) {
      this.toastr.clear();
      this.toastr.error('Verification Code is Incorrect');
      return
    }
    this.isLoading = true;
    this.loginService.verifyOTP(this.code).subscribe(resp => {
      this.isLoading = false;
      if (!resp.data.success) {
        this.toastr.clear();
        this.toastr.error('Verification Code is Incorrect');
      } else {
        this.router.navigateByUrl('/n/dashboard').then(r => {
          const angularJsScope = angular.element(document.getElementById('angularjs-container')).scope();
          if (angularJsScope) {
            angularJsScope.$state.go('main.ng2', {}, {notify: false});
          } else {
            console.error('Where is angular scope???', angularJsScope);
          }
        });
      }
    }, e => this.isLoading = false)

  }

  logout() {
    this.store.dispatch(logout());
  }

}
