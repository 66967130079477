import { createAction, props } from "@ngrx/store";

export const loadPropertyOptions = createAction(
  "[Dashboard] Load Property Options"
);

export const setPropertyOptions = createAction(
  "[Dashboard] Set Property Options",
  props<{ properties: {id: number, name: string}[] }>()
);
