import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';

import {Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {State} from '../../store/app.state';
import {User} from '../interfaces/user';
import {selectAppUser} from '../../store/app.selectors';
import {catchError, exhaustMap, filter, take, tap} from 'rxjs/operators';
import {loadUser} from '../../store/app.actions';
import {APP_USER_STATUS} from '../constants'

export const TwoFactorAuthGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const store: Store<State> = inject(Store<State>);

  const getUserFromStateOrAPI = (): Observable<User | null | APP_USER_STATUS.NOT_AUTHORIZED> => {
    return store.pipe(
      select(selectAppUser),
      tap((user: User | null | APP_USER_STATUS.NOT_AUTHORIZED) => {
        if (user === null) {
          store.dispatch(loadUser());
        }
      }),
      filter((user: User | null | APP_USER_STATUS.NOT_AUTHORIZED) => user !== null),
      take(1)
    );
  };

  return getUserFromStateOrAPI().pipe(
    exhaustMap((u) => {
      if (u === null || u === APP_USER_STATUS.NOT_AUTHORIZED) {
        window.location.href = '/#/n/login';
        return of(false);
      }
      if (u['2_fa_install_required']) {
        window.location.href = '/#/n/2fa/setup';

      }
      return of(true);
    }),
    catchError(() => of(false))
  );
};
