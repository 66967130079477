<div class="panel panel-default">

  <div class="panel-heading">
    <div class="panel-title">
      <div class="row">
        <a class="accordion-toggle" href="javascript:void(0)" (click)="toggle()">
          <div class="col-xs-12">
            <ng-content select="app-accordion-item-header"></ng-content> &nbsp;<span class="caret"></span>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div class="panel-collapse" [@slideDownAnimation]="isOpen ? 'open' : 'closed'">
    <div class="panel-body">
      <ng-content select="app-accordion-item-body"></ng-content>
    </div>
  </div>
</div>
