import { Component, Input } from '@angular/core';
import { slideDownAnimation } from '../../../animations/slide-down.animation';

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  animations: [slideDownAnimation]
})
export class AccordionItemComponent {
  @Input() isOpen = false;

  public toggle(): void {
    this.isOpen = !this.isOpen;
  }
}
