<div class="sidenav-header">
  <img class="avatar" src="dist/assets/icons/Profile Avatar.svg"/>
  <p class="h7 user-name">{{authUser.first_name}} {{authUser.last_name}}</p>
  <p class="body3 unit-name">
    <span style="text-transform: capitalize;" *ngIf="authUser.permissions === 'payquad_admin'">PQ Admin</span>
    <span style="text-transform: capitalize;" *ngIf="authUser.permissions === 'junior_manager'">{{t('Junior Manager')}}</span>
    <span style="text-transform: capitalize;" *ngIf="authUser.permissions === 'admin_manager'">{{t('Admin Manager')}}</span>
  </p>
  <mat-divider></mat-divider>
</div>
<mat-nav-list class="profile-nav body3">
  <a mat-list-item href="/#/account">
    <mat-icon mat-list-item-icon svgIcon="pq:user"></mat-icon>
    <span mat-list-item-title class="body3">{{ t('profile') }}</span>
  </a>
  <a mat-list-item href="/#/audit-log" *ngIf="authUser.permissions === 'payquad_admin'">
    <mat-icon mat-list-item-icon svgIcon="pq:settings"></mat-icon>
    <span mat-list-item-title class="body3">{{'Audit Log'}}</span>
  </a>
  <a mat-list-item href="/#/companies/0/edit" *ngIf="authUser.permissions === 'admin_manager'">
    <mat-icon mat-list-item-icon svgIcon="pq:settings"></mat-icon>
    <span mat-list-item-title class="body3">{{t('Company Settings')}}</span>
  </a>
  <a mat-list-item href="/#/billing/invoices" *ngIf="authUser.user_permissions.billing">
    <mat-icon mat-list-item-icon svgIcon="pq:invoice-accounting-document"></mat-icon>
    <span mat-list-item-title class="body3">{{t('Billing Invoices')}}</span>
  </a>
  <a mat-list-item href="/#/property-perks" *ngIf="(authUser.permissions === 'admin_manager' && authUser.property_perks == true) || authUser.permissions === 'payquad_admin'">
    <mat-icon mat-list-item-icon svgIcon="pq:star-checkmark"></mat-icon>
    <span mat-list-item-title class="body3">{{t('Property Perks')}}</span>
  </a>
  <a mat-list-item href="/#/management-resources">
    <mat-icon mat-list-item-icon svgIcon="pq:users-profile-group"></mat-icon>
    <span mat-list-item-title class="body3">{{t('Management Resources') }}</span>
  </a>
  <a mat-list-item href="#/n/offline/sync-batch">
    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="settings"></mat-icon>
    <span mat-list-item-title class="body3">{{'Offline Preferences'}}</span>
  </a>
  <a mat-list-item href="/#/rewards" *ngIf="authUser.permissions === 'junior_manager' && authUser.property_perks == true">
    <mat-icon mat-list-item-icon svgIcon="pq:discount"></mat-icon>
    <span mat-list-item-title class="body3">{{t('rewards')}}</span>
  </a>
  <a mat-list-item href="#/n/mobile-apps/list" *ngIf="authUser.permissions === 'payquad_admin'">
    <mat-icon mat-list-item-icon svgIcon="pq:settings"></mat-icon>
    <span mat-list-item-title class="body3">{{'Mobile Apps'}}</span>
  </a>
  <a mat-list-item (click)="signOut()">
    <mat-icon mat-list-item-icon svgIcon="pq:login-logout-arrow"></mat-icon>
    <span mat-list-item-title class="body3">{{ t('sign_out') }}</span>
  </a>
</mat-nav-list>
