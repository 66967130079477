<div class="filter-container">
  <div *ngIf="filtersCount !== 0" class="filter-control-container">
    <mat-card
      class="filter-card"
      [style.width]="getFiltersCardWidth()"
    >
      <mat-card-content>
        <div class="row">
          <div *ngIf="enablePropertyFilter" [ngClass]="getFiltersStylingClass()">
            <div class="multi-select">
              <table-multiple-select
                class="select-control"
                label="Properties"
                [control]="propertyFilterControl"
                [options]="propertyList|async"
                [transpiler]="propertiesTranspiler"
                [useMoreLabel]="true"
                [allSelectedLabel]="'All Properties' | translate"
                [inline]="true" #select>
              </table-multiple-select>
            </div>
          </div>
          <div *ngIf="enableUnitFilter" [ngClass]="getFiltersStylingClass()">
            <div class="multi-select">
              <table-multiple-select
                class="select-control"
                label="Units"
                [control]="unitFilterControl"
                [options]="unitList|async"
                [transpiler]="unitsTranspiler"
                [useMoreLabel]="true"
                [allSelectedLabel]="'All Units' | translate"
                [inline]="true" #select>
              </table-multiple-select>
            </div>
          </div>
          <div *ngIf="enableStatusFilter" [ngClass]="getFiltersStylingClass()">
            <div class="multi-select d-flex justify-content-center align-items-center">
              <table-single-select
                [label]="'Status'"
                [control]="statusFilterControl"
                [options]="statusList|async"
                [transpiler]="statusesTranspiler">
              </table-single-select>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="search-container" [ngClass]="{'custom-search': customFilters && customFilters.length > 0, 'float-right': filtersCount !== 0, 'float-left': filtersCount === 0}"> 
    <form action (ngSubmit)="handleSearch()" class="row">
      <mat-form-field class="search-field col-xs-12" [ngClass]="{'col-md-6':  customFilters && customFilters.length > 0}">
        <mat-label>{{"Search" | translate}}</mat-label>
        <input
          matInput
          type="search"
          [formControl]="searchFieldControl"
        />
        <button mat-icon-button matPrefix>
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
      <span *ngIf="customFilters && customFilters.length > 0" class="vertical-line"></span>
      <mat-form-field *ngIf="customFilters && customFilters.length > 0" class="column-field col-xs-12 col-md-6">
        <!-- <mat-label>{{"Filter Type" | translate}}</mat-label> -->
        <mat-select [(ngModel)]="selectedFilter" name="selectedOption">
          <mat-option *ngFor="let option of customFilters" [value]="option.key">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
</div>

<div class="row filter-display-container">
  <div class="chips-container" #filterContainer>
    <mat-chip-listbox #chipList>
      <ng-container *ngFor="let filter of filtersChipsData | async; let i = index">
        <mat-chip
          [ngClass]="{'chip-hidden': isChipHidden(i)}"
          [removable]="true"
          (removed)="handleRemoveFilter(filter)"
        >
          <strong *ngIf="filter.filterName">{{filter.filterName}}:</strong>
          {{ filter.label }}
          <button mat-icon-button matChipRemove [attr.aria-label]="'remove ' + filter.label + ' chip'">
            <mat-icon>close</mat-icon>
          </button>
        </mat-chip>
      </ng-container>
    </mat-chip-listbox>
    <div class="clear-button-container" *ngIf="(filtersChipsData | async).length !== 0">
      <button mat-button (click)="handleClearFilters()">
        {{"Clear all" | translate}}
      </button>
    </div>
  </div>
  <ng-container *ngIf="isChipsExceedOneLine">
    <div class="filter-button-container">
      <button mat-button (click)="showAllChips = !showAllChips">
        <strong>{{ (showAllChips ? 'Show Less'  : 'Show More') | translate }}</strong>
      </button>
    </div>
  </ng-container>
</div>
