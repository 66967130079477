<ng-container *ngIf="appVersionService.isValid || !window.cordova">
  <app-layout *ngIf="isAngular2"></app-layout>
</ng-container>
<ng-container *ngIf="!appVersionService.isValid && window.cordova">
  <section style="text-align: center">
    <h1>The App Needs to be Updated</h1>
    <h2 *ngIf="appVersionService.platform === 'ios'">Please download the newest version from the App Store.</h2>
    <h2 *ngIf="appVersionService.platform === 'android'">Please download the newest version from the Google Play.</h2>

    <div>
      <a *ngIf="appVersionService.platform === 'ios'" href="{{appVersionService.link}}">
        <img src="/assets/images/download-on-the-app-store.png" style="height:86px; margin-left:10px;">
      </a>

      <a *ngIf="appVersionService.platform === 'android'" (click)="appVersionService.openStoreLink(appVersionService.link)">
        <img src="/assets/images/google-play-badge.png" style="width:210px; margin-top:2px">
      </a>
    </div>
  </section>
</ng-container>
<div class="loading-page-spinner" [hidden]="!isLoading">
  <i class="fa fa-spinner fa-pulse"></i>
</div>
