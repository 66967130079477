<div class="container" style="margin-bottom:30px">
  <div class="row">
    <div class="col-xs-12 align-center">
      <div class="companyicon">
        <img ng-hide="branding.hideCompanyLogo" src="{{branding.logo_file_url}}"/>
      </div>
    </div>
  </div>
</div>

<div class="container-1024" *ngIf="step === '2fa'" >
    <app-set-up (twoFaLogOut)="step='login'" [isEmbedded]="true"></app-set-up>
</div>

<div class="container-1024" style="margin-top:-20px" *ngIf="step === 'login'" >
  <div class="row" align="center" style="margin-bottom: 10px">
    <div style="
        height: 1px;
        margin-bottom: 30px;
  overflow: visible;
  border: none;
  background: none;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(150, 150, 150, 0)), color-stop(15%, rgba(150, 150, 150, 0)), color-stop(50%, rgba(150, 150, 150, 0.65)), color-stop(85%, rgba(150, 150, 150, 0)), color-stop(100%, rgba(150, 150, 150, 0)));
  background: -webkit-linear-gradient(left, rgba(150, 150, 150, 0) 0%, rgba(150, 150, 150, 0) 15%, rgba(150, 150, 150, 0.65) 50%, rgba(150, 150, 150, 0) 85%, rgba(150, 150, 150, 0) 100%);
  background: -moz-linear-gradient(left, rgba(150, 150, 150, 0) 0%, rgba(150, 150, 150, 0) 15%, rgba(150, 150, 150, 0.65) 50%, rgba(150, 150, 150, 0) 85%, rgba(150, 150, 150, 0) 100%);
  background: -ms-linear-gradient(left, rgba(150, 150, 150, 0) 0%, rgba(150, 150, 150, 0) 15%, rgba(150, 150, 150, 0.65) 50%, rgba(150, 150, 150, 0) 85%, rgba(150, 150, 150, 0) 100%);
  background: -o-linear-gradient(left, rgba(150, 150, 150, 0) 0%, rgba(150, 150, 150, 0) 15%, rgba(150, 150, 150, 0.65) 50%, rgba(150, 150, 150, 0) 85%, rgba(150, 150, 150, 0) 100%);
  background: linear-gradient(left, rgba(150, 150, 150, 0) 0%, rgba(150, 150, 150, 0) 15%, rgba(150, 150, 150, 0.65) 50%, rgba(150, 150, 150, 0) 85%, rgba(150, 150, 150, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#00000000', GradientType=1);"></div>
    <div
      *ngIf="branding.welcome_message != '' && branding.welcome_message != null && branding.welcome_message !== undefined"
      [innerHTML]="branding.welcome_message" style="margin-bottom: 30px"></div>
  </div>

  <div class="row show-mobile" style="padding: 0 15px">
    <span class="pull-right" style="margin-bottom: 2em;">
      <div class="dropdown">
          <button class="btn btn-primary dropdown-toggle" type="button"
                  data-toggle="dropdown">{{ localeButtonString }}
            <span class="caret"></span>
          </button>
          <ul class="dropdown-menu">
             <li><a href="javascript:void(0)" (click)="setLocalization('en')">En</a></li>
          <li><a href="javascript:void(0)" (click)="setLocalization('fr')">Fr</a></li>
          </ul>
      </div>
    </span>
  </div>

  <div class="row" style="padding: 0 15px">

    <div class="col-sm-7 col-xs-12 loginBox">
      <h1> {{localeButtonString === 'fr' ? 'Se Connecter' : 'Sign In'}} </h1>

      <div class="row">
        <div class="form-item">
          <p class="bg-danger" id="error-messages-container" *ngFor="let error of errors | keyvalue">
            {{ error.value[0] }}
          </p>
        </div>
      </div>

      <div class="row">
        <form (submit)="login()" class="form-horizontal large-inputs " role="form" id="signin-form">
          <div class="col-xs-12 form-item">
            <input [(ngModel)]="authData.email"
                   autocomplete="on"
                   id="email-input"
                   class="form-control"
                   name="email"
                   type="email"
                   placeholder="{{localeButtonString === 'fr' ? 'Adresse De Courriel' : 'Enter Email'}}">
          </div>
          <div class="col-xs-12 form-item">
            <input [(ngModel)]="authData.password"
                   autocomplete="on"
                   type="password"
                   id="password-input"
                   class="form-control"
                   name="password"
                   placeholder="{{localeButtonString === 'fr' ? 'Mot De Passe' : 'Password'}}">
          </div>
          <div class="row" style="  margin-bottom: 10px;">
            <div class="col-xs-8 col-xs-offset-2" style="text-align: center">
              <input type="submit" value="{{localeButtonString == 'fr' ? 'ACCÈS' : 'LOGIN'}}" *ngIf="!loggingIn" class="btn btn-primary">
              <img src="/assets/images/loading.gif" width="25%" *ngIf="loggingIn" />
            </div>
          </div>
        </form>

        <div class="col-xs-7">
          <div class="checkbox form-item remember-me">
            <label>
              <input type="checkbox"
                     id="remember-input"
                     name="remember"
                     [(ngModel)]="authData.remember_me"
              >
              {{localeButtonString == 'fr' ? 'Mémoriser mon courriel' : 'Remember my email'}}
            </label>
          </div>
        </div>

        <div class="col-xs-5">
          <div class="form-item forgot-passtext-right-sm pull-right">
            <a href="javascript:void(0)" (click)="goForgotPassword()">{{localeButtonString == 'fr' ? 'Mot de passe oublié?' : 'Forgot password?'}}</a>
          </div>
        </div>

      </div>

    </div>

    <div class="col-xs-12 col-sm-5">
            <span class="pull-right hide-mobile" ng-if="1">
                <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle" type="button"
                            data-toggle="dropdown">{{ localeButtonString }}
                      <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu">
                        <li><a href="javascript:void(0)" (click)="setLocalization('en')">En</a></li>
                        <li><a href="javascript:void(0)" (click)="setLocalization('fr')">Fr</a></li>
                    </ul>
                </div>
            </span>

      <h1>{{localeButtonString == 'fr' ? 'S\'enregistrer' : 'Sign Up'}}</h1>
      <div
        ng-if="branding.login_register_message != '' && branding.login_register_message != null && branding.login_register_message !== undefined"
        compile="branding.login_register_message" ng-click="openLink($event)"></div>
      <div ng-if="branding.login_register_message == '' || branding.login_register_message == null || branding.login_register_message === undefined">
        <p style="margin-top:20px;">{{localeButtonString == 'fr' ? 'Vous n\'êtes pas membre Payquad? Inscrivez-vous et accédez à ces excellents services!' : 'Not a PayQuad Member? Register to gain access to these great services!'}}</p>
        <div class="row">
          <ul>
            <li>{{localeButtonString == 'fr' ? 'Mise à jour des nouvelles communautaires' : 'Community news updates'}}</li>
            <li>{{localeButtonString == 'fr' ? 'Montants prévus' : 'Scheduled payments'}}</li>
            <li>{{localeButtonString == 'fr' ? 'Historique de paiements' : 'Payment history'}}</li>
          </ul>
        </div>
      </div>
      <div class="row" style="margin-top:5px; margin-bottom: 10px;">
        <div class="col-xs-12 col-sm-6 col-sm-offset-2">
          <a href="javascript:void(0)" (click)="goRegister()">
            <button type="button"
                    id="register-btn"
                    class="btn btn-default">
              {{localeButtonString == 'fr' ? 'S\'inscrire' : 'REGISTER'}}
            </button>
          </a>
        </div>
      </div>
      <div class="row" style="margin-top:5px; margin-bottom: 10px;" ng-if="platform === 'web'">
        <div class="col-xs-6 col-sm-6">
          <div class="form-item forgot-passtext-right-sm">
            <a href="{{branding.android_application_link}}">
              <img src="/assets/images/google-play-badge.png" style="max-width: 210px;width: 100%;margin-top:2px">
            </a>
          </div>
        </div>
        <div class="col-xs-6 col-sm-6">
          <div class="form-item forgot-passtext-right-sm">
            <a href="{{branding.ios_application_link}}">
              <img src="/assets/images/download-on-the-app-store.png"
                   style="max-width: 210px;width: 100%;margin-left:10px;">
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<div class="modal fade"
     id="update-mobile-app-modal"
     tabindex="-1"
     role="dialog"
     data-keyboard="false" data-backdrop="static"
     aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header" style="text-align: center">
        <h2>{{'The App Needs to be Updated' | translate}}</h2>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h3 ng-if="platform === 'ios'">Please download the newest version from the App Store"</h3>
            <h3 ng-if="platform === 'android'"> Please download the newest version from the Google Play/</h3>
          </div>
        </div>
        <div class="row">
          <div ng-if="platform === 'ios' && pqAppId === 'com.payquad'">
            <div class="form-item text-center">
              <a href="https://apps.apple.com/ca/app/my-portal-by-payquad/id1502174216">
                <img src="/assets/images/download-on-the-app-store.png" style="height:86px; margin-left:10px;">
              </a>
            </div>
          </div>

          <div ng-if="platform === 'ios' && pqAppId === 'com.payquad.manager'">
            <div class="form-item text-center">
              <a href="https://apps.apple.com/ca/app/manager-app-by-payquad/id1551011738">
                <img src="/assets/images/download-on-the-app-store.png" style="height:86px; margin-left:10px;">
              </a>
            </div>
          </div>


          <div ng-if="platform === 'ios' && pqAppId === 'com.medallion.app'">
            <div class="form-item text-center">
              <a href="https://apps.apple.com/ca/app/my-medallion/id1555362357">
                <img src="/assets/images/download-on-the-app-store.png" style="height:86px; margin-left:10px;">
              </a>
            </div>
          </div>

          <div ng-if="platform === 'android' && pqAppId === 'com.payquad'">
            <div class="form-item text-center">
              <a ng-click="openStoreLink(platform, pqAppId)">
                <img src="/assets/images/google-play-badge.png" style="width:210px; margin-top:2px">
              </a>
            </div>
          </div>

          <div ng-if="platform === 'android' && pqAppId === 'com.payquad.manager'">
            <div class="form-item text-center">
              <a ng-click="openStoreLink(platform, pqAppId)">
                <img src="/assets/images/google-play-badge.png" style="width:210px; margin-top:2px">
              </a>
            </div>
          </div>

          <div ng-if="platform === 'android' && pqAppId === 'com.payquad'">
            <div class="form-item text-center">
              <a ng-click="openStoreLink(platform, pqAppId)">
                <img src="/assets/images/google-play-badge.png" style="width:210px; margin-top:2px">
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
