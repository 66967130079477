import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {debounceTime, distinct, distinctUntilChanged, pairwise, share, switchMap, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  public sidenavOpened = new BehaviorSubject<boolean>(true);

  public scrollTop = new BehaviorSubject<number>(0);
  public $scrollTop = this.scrollTop.pipe(debounceTime(50));
  // public isMobile: Observable<boolean>;

  cssMaxWidth = new BehaviorSubject<number>(window.innerWidth);
  public isMobile: Observable<boolean> = this.cssMaxWidth.pipe(
    switchMap(d => [d <= 840]),
    distinctUntilChanged(),
    debounceTime(100),
    tap((isMobile) => this.sidenavOpened.next(!isMobile)),
    share(),
  );

  public isPrint = new BehaviorSubject<boolean>(false);

  toggleSidenav() {
    this.sidenavOpened.next(!this.sidenavOpened.value);
  }
  constructor() {
    window.onbeforeprint = () => {
      this.isPrint.next(true);
    };
    window.onafterprint = () => {
      this.isPrint.next(false);
    };
  }
}
