import { createAction, props } from '@ngrx/store';
import { User } from '../shared/interfaces/user';
import { Branding } from '../shared/interfaces/branding';
import {APP_USER_STATUS} from '../shared/constants';

export enum appActions {
  SET_USER = '[App] Set user',
  LOAD_USER = '[App] Load user',
  CHANGE_USER = '[App] Change user',
  SET_BRANDING = '[App] Set branding',
  LOAD_BRANDING = '[App] Load branding',
  LOGOUT = '[App] Log out',
  LOAD_TRANSLATIONS = '[App] Load translations',
  SET_TRANSLATIONS = '[App] Set translations',
  SET_TRANSLATION = '[App] Set translation',
  SET_LOCALE = '[App] Set locale',
  SET_CURRENT_PAGE = '[App] Set current page',
}

export const setUser = createAction(appActions.SET_USER, props<{user: User | null | APP_USER_STATUS.NOT_AUTHORIZED}>());
export const loadUser = createAction(appActions.LOAD_USER);
export const changeUser = createAction(appActions.CHANGE_USER, props<{id: number}>());
export const setBranding = createAction(appActions.SET_BRANDING, props<{branding: Branding}>());
export const loadBranding = createAction(appActions.LOAD_BRANDING);
export const logout = createAction(appActions.LOGOUT);
export const sessionExpired = createAction("[App] Session expired");
export const loadTranslations = createAction(appActions.LOAD_TRANSLATIONS, props<{locale: string}>());
export const setTranslations = createAction(appActions.SET_TRANSLATIONS, props<{translations: any}>());
export const setTranslation = createAction(appActions.SET_TRANSLATION, props<{key: string, value: any}>());
export const setLocale = createAction(appActions.SET_LOCALE, props<{locale: 'en' | 'fr' }>());
export const setCurrentPage = createAction(appActions.SET_CURRENT_PAGE, props<{currentPage: string}>());

export const logoutSuccess = createAction(
  "[App] Logout Success"
);
export const httpError = createAction(
  "[App] HTTP Error",
  props<{ payload: { message?: string } }>()
);

