<mat-form-field xmlns="" [ngClass]="{'mat-form-field-inline-text': inline}">
  <mat-select [formControl]="control" panelClass="multi-select-panel-custom-width" [placeholder]="label" multiple #matSelect>
    <mat-select-trigger *ngIf="useMoreLabel || allSelectedLabel">
      <ng-container *ngIf="allSelectedLabel && allOptionsSelected">
        {{allSelectedLabel}}
      </ng-container>
      <ng-container *ngIf="useMoreLabel && !allOptionsSelected">
        {{getSelectedOptionLabel(control.value[0]) || ''}}
        <span *ngIf="(control.value?.length || 0) > 1" class="example-additional-selection">
          (+{{ (control.value?.length || 0) - 1 }} {{ control.value?.length === 2 ? 'other' : 'others' | translate }})
        </span>
      </ng-container>
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search
        [formControl]="searchControl"
        [placeholderLabel]="'Search...' | translate"
        [noEntriesFoundLabel]="'No matches found' | translate"
        [showToggleAllCheckbox]="true"
        [toggleAllCheckboxChecked]="allOptionsSelected"
        [toggleAllCheckboxTooltipMessage]="'Select all' | translate"
        (toggleAll)="toggleSelectAll($event)">
          <mat-icon svgIcon="pq:trash" ngxMatSelectSearchClear></mat-icon>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let option of filteredOptions" [value]="option.value">{{option.label}}</mat-option>
  </mat-select>
</mat-form-field>
