import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { State } from '../../store/app.state';
import { User } from '../interfaces/user';
import { selectAppUser } from '../../store/app.selectors';
import { catchError, exhaustMap, filter, map, take, tap } from 'rxjs/operators';
import { loadUser } from '../../store/app.actions';
import { APP_USER_STATUS, APP_USER_ROLES } from '../constants'
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

export const ManagerGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const store: Store<State> = inject(Store<State>);
  const toastr: ToastrService = inject(ToastrService);
  const t: TranslateService = inject(TranslateService);

  const getUserFromStateOrAPI = (): Observable<User | null> => {
    return store.pipe(
      select(selectAppUser),
      tap((user: User | null | APP_USER_STATUS.NOT_AUTHORIZED) => {
        if (user === null) {
          store.dispatch(loadUser());
        }
      }),
      filter((user: User | null | APP_USER_STATUS.NOT_AUTHORIZED) => {
        return user !== null;
      }),
      map((user) => user === APP_USER_STATUS.NOT_AUTHORIZED ? null : user as User),
      take(1)
    );
  }

  return getUserFromStateOrAPI().pipe(
    exhaustMap((u) => {
      if (u.permissions !== APP_USER_ROLES.TENANT) {
        return of(true);
      } else {
        toastr.error(t.instant('You do not permission to access this page'));
        window.location.href = '/#/dashboard';
        return of(false);
      }
    }),
    catchError(() => of(false)),
  )
}
