import { Injectable } from "@angular/core";
import { Branding } from "../interfaces/branding";
import { Store } from "@ngrx/store";
import { State } from "src/app/store/app.state";
import { selectAppBranding } from "src/app/store/app.selectors";
import tinycolor from "tinycolor2";
import { tap } from "rxjs/operators";

export class Colour {
    constructor(public name: string, public hex: string) {
        this.hex = hex.startsWith("#") || hex.startsWith("rgb") ? hex : "#" + hex;
    }
}

@Injectable({
    providedIn: "root",
})
export class CustomisationService {

    // if going to use config to get the config/branding, check new portal on how to set up distinctUntilChanged so it doesn't call setUpPalette every time subscribed and so new Branding every time log in 
    config = this.store.select(selectAppBranding).pipe(
        tap((branding: Branding) => {
            this.setUpPalette(branding.primary_colour, branding.secondary_colour);
        })
    );

    constructor(private store: Store<State>) { }

    setUpPalette(primary_colour: string, secondary_colour: string) {
        const primary = this.generatePalette("primary", primary_colour);
        const secondary = this.generatePalette("secondary", secondary_colour);
        this.writeProps(primary);
        this.writeProps(secondary);
    }

    writeProps(palette: Colour[]) {
        palette.forEach((c) => {
            document.documentElement.style.setProperty(c.name, c.hex);
        });
    }

    generatePalette(name: string, colour: string): Colour[] {
        return [
            new Colour(`__${name}`, colour),
            new Colour(`--${name}-1`, colour),
            new Colour(`__${name}-2`, tinycolor(colour).lighten(7).toHex()),

            new Colour(
                `--${name}-t-7`,
                tinycolor(colour).setAlpha(0.7).toRgbString()
            ),

            new Colour(`--${name}-50`, tinycolor(colour).lighten(80).toHex()),
            new Colour(`--${name}-100`, tinycolor(colour).lighten(60).toHex()),
            new Colour(`--${name}-200`, tinycolor(colour).lighten(50).toHex()),
            new Colour(`--${name}-300`, tinycolor(colour).lighten(40).toHex()),
            new Colour(`--${name}-400`, tinycolor(colour).lighten(30).toHex()),
            new Colour(`--${name}-500`, tinycolor(colour).lighten(20).toHex()),
            new Colour(`--${name}-600`, tinycolor(colour).lighten(10).toHex()),
            // new Colour(`--${name}-700`, tinycolor(colour).toHex()), // set up throw var(__primary)
            new Colour(`--${name}-800`, tinycolor(colour).darken(7).toHex()),
            new Colour(`--${name}-900`, tinycolor(colour).darken(14).toHex()),
        ];
    }
}
