<ng-container *ngIf="authUser && (authUser.permissions === roles.ADMIN || authUser.permissions === roles.MANAGER || authUser.permissions === roles.JUNIOR_MANAGER) && router.url !== '/n/2fa'&& router.url !== '/n/2fa/setup'; else tenantLayout">
  <div class="toolbar-container">
    <app-navbar></app-navbar>
  </div>
<!--  <mat-sidenav-container class="sidebar-closed">-->
  <mat-sidenav-container [ngClass]="{'sidebar-closed': !(layoutService.sidenavOpened|async) && !(layoutService.isMobile|async)}" #matSidenavContainer>
    <mat-sidenav role="navigation"
                 [mode]="(layoutService.isMobile|async) ? 'over' : 'side'"
                 [fixedInViewport]="true"
                 [opened]="(layoutService.isMobile|async) ? (layoutService.sidenavOpened|async) : true"
                 [fixedTopGap]="(layoutService.isMobile|async) ? 57 : 70"
                 #matSidenav
    >
      <app-sidenav [authUser]="authUser"></app-sidenav>
    </mat-sidenav>
<!--    <ng-container *ngIf="layoutService.isMobile|async">-->
<!--      <mat-sidenav [mode]="'push'"-->
<!--                   [fixedInViewport]="true"-->
<!--                   [fixedTopGap]="70"-->
<!--                   #matSidenav-->
<!--      >-->
<!--        <app-sidenav></app-sidenav>-->
<!--      </mat-sidenav>-->
<!--    </ng-container>-->
<!--    <ng-container *ngIf="!(layoutService.isMobile|async)">-->
<!--      <mat-sidenav [mode]="'side'"-->
<!--                   [fixedInViewport]="true"-->
<!--                   [opened]="true"-->
<!--                   [fixedTopGap]="70"-->
<!--                   #matSidenav-->
<!--      >-->
<!--        <app-sidenav></app-sidenav>-->
<!--      </mat-sidenav>-->
<!--    </ng-container>-->
    <mat-sidenav-content [ngClass]="{'mobile': layoutService.isMobile|async}">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>

<ng-template #tenantLayout>
  <div class="flex_container">
    <div *ngIf="authUser && router.url !== '/n/2fa' && router.url !== '/n/2fa/setup'"><app-navbar></app-navbar></div>
    <div [ngClass]="{'container': router.url !== '/n/payments/bulk-edit'}" id="newng_content">
      <router-outlet></router-outlet>
    </div>
    <div><app-footer></app-footer></div>
  </div>
</ng-template>

