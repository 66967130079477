import {Injectable} from '@angular/core';
import { JWTServiceInterface } from './JWT/JWT-service.interface';
import { JWTAndriodService } from './JWT/JWT-android.service';
import { JWTIosService } from './JWT/JWT-ios.service';
import { JWTWebService } from './JWT/JWT-web.service';

@Injectable({
  providedIn: 'root'
})
export class JWTService implements JWTServiceInterface {
  private JWTServiceObject = null;
  // @ts-ignore
  private isCordova = Boolean(window.cordova);

  constructor() {
    if(!this.isCordova){
      this.JWTServiceObject = new JWTWebService();
    }
    // @ts-ignore
    else if(window.cordova.platformId === 'android' || window.cordova.platformId === 'browser'){
      console.log('android service');
      this.JWTServiceObject = new JWTAndriodService();
    }
    else{
      console.log('ios');

      this.JWTServiceObject = new JWTIosService();
    }
  }

  async getToken() {
    return this.JWTServiceObject.getToken();
  }

  setToken(token = null, type = 'Bearer', expired = 0) {
    return this.JWTServiceObject.setToken(token, type, expired);
  }

  removeToken() {
    return this.JWTServiceObject.removeToken();
  }
}
