import {Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy} from '@angular/core';
import moment from 'moment';
@Pipe({
  name: 'filterTags',
  pure: false
})
export class FilterTagsPipe implements PipeTransform {
  transform(items: any[], searchText: string, inverse: boolean = false): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    if (inverse) {
      return items.filter(it => !it.toLowerCase().includes(searchText));
    } else {
      return items.filter(it => it.toLowerCase().includes(searchText));
    }
  }
}
