import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../shared/interfaces/user';
import {Store} from '@ngrx/store';
import {State} from '../../../store/app.state';
import {loadTranslations, logout, setCurrentPage, setLocale} from '../../../store/app.actions';
import {UnsubscribableComponent} from '../../../shared/classes/abstract/unsubscribable-component';
import {Branding} from '../../../shared/interfaces/branding';
import {selectAppBranding, selectAppCurrentPage, selectAppLocale} from '../../../store/app.selectors';
import {ANGULAR_JS_EVENTS} from "../../../shared/constants";
import {AngularJsService} from "../../../shared/services/angular-js.service";
import {LayoutService} from '../../../shared/services/layout.service';
import {MessagesService} from '../../../modules/messages/messages.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { MobileHelper } from 'src/app/shared/helpers/mobile-helper';

@Component({
  selector: 'app-manager-navbar',
  templateUrl: './manager-navbar.component.html',
  styleUrls: ['./manager-navbar.component.scss']
})
export class ManagerNavbarComponent extends UnsubscribableComponent implements OnInit, OnDestroy {

  @Input()
  public authUser: User;
  public isCordova = true;
  public mobileMenus = {};
  public isMobile = true;

  public localeButtonString = 'En';
  public title = new BehaviorSubject('');

  public branding: Branding;
  public activeInMobile: any[];

  public hasNewMessagingAccess = false;

  constructor(
    private store: Store<State>,
    protected angularJsService: AngularJsService,
    public layoutService: LayoutService,
    public messagesService: MessagesService,
    private http: HttpClient,
    private mobileHelper: MobileHelper,
  ) {
    super();
  }

  ngOnInit() {
    this.sub = this.store.select(selectAppBranding).subscribe(branding =>
      this.branding = branding
    );
    this.sub = this.store.select(selectAppLocale).subscribe(locale =>
      this.localeButtonString = locale
    );
    this.sub = this.store.select(selectAppCurrentPage).subscribe(currentPage => {
      this.title.next(currentPage);
    })
    // @ts-ignore
    this.isCordova = Boolean(window.cordova);

    this.isMobile = this.mobileHelper.isMobileOrTablet();

    this.mobileMenus =  {
      companies: {
        isActive: this.showModule() && this.authUser.permissions === 'payquad_admin',
        menuNumber: 0
      },
      properties: {
        isActive: this.showModule() && this.authUser.permissions !== 'payquad_admin' && this.authUser.user_permissions.properties,
        menuNumber: 1
      },
      residents: {
        isActive: this.showModule() && this.authUser.user_permissions.residents,
        menuNumber: 2
      },
      payments: {
        isActive: this.showModule() && this.authUser.user_permissions.payments,
        menuNumber: 3
      },
      managers: {
        isActive: this.showModule() && this.authUser.permissions !== 'junior_manager',
        menuNumber: 4
      },
      communication: {
        isActive: this.showModule() && this.authUser.user_permissions['communications'],
        menuNumber: 5
      },
      maintenance: {
        isActive: this.showModule() && this.authUser.user_permissions['maintenance-requests'],
        menuNumber: 6
      },
      reports: {
        isActive: this.showModule() && this.authUser.user_permissions.reports,
        menuNumber: 7
      },
      documents: {
        isActive: this.showModule() && this.authUser.user_permissions.documents,
        menuNumber: 8
      },
      amenities: {
        isActive: this.showModule() && this.authUser.user_permissions.amenities,
        menuNumber: 9
      },
      surveys: {
        isActive: this.showModule() && this.authUser.user_permissions.surveys,
        menuNumber: 10
      },
      lease: {
        isActive: this.showModule() && this.authUser.user_permissions['lease-applications'],
        menuNumber: 11
      },
      parcels: {
        isActive: this.showModule() && this.authUser.user_permissions.parcels,
        menuNumber: 12
      },
      inspections: {
        isActive: this.showModule() && this.authUser.user_permissions.inspections,
        menuNumber: 13
      },
    };

    this.activeInMobile = [];

    let obj = this.mobileMenus;

    for (let key in obj) {
      if (obj[key].isActive) {
        this.activeInMobile.push(obj[key].menuNumber);
      }
    }

    // tslint:disable-next-line:only-arrow-functions
    this.activeInMobile = this.activeInMobile.sort(function(a, b) {
      return a - b;
    });

    let numberOfMenuItemsToShow = 4;

    this.activeInMobile = this.activeInMobile.slice(0, numberOfMenuItemsToShow);

  }

  public showModule() {
    if (this.isCordova) {
      return true;
    } else {
      return true;
    }
  }

  public isMenuItemActiveInMobile(menuNumber) {
    if (!this.isCordova && !this.isMobile) {
      // return false;
    }
    return !this.activeInMobile.includes(menuNumber);
  }

  public signOut(): void {
    this.store.dispatch(logout());
  }

  public goToCompanies(): void {
    window.location.href = `/#/n/companies`;
  }

  public goToDashboard(): void {
    this.store.dispatch(setCurrentPage({currentPage: 'Dashboard'}));
    window.location.href = `/#/n/dashboard`;
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  public setLocalization(locale: 'en' | 'fr'): void {
    this.store.dispatch(setLocale({locale}));
    this.store.dispatch(loadTranslations({locale}));
    this.angularJsService.fireEvent(ANGULAR_JS_EVENTS.ANGULAR_JS_LANG_CHANGED, locale);
  }

}
