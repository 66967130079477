import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {selectAppUser} from '../store/app.selectors';
import {APP_USER_ROLES, APP_USER_STATUS} from '../shared/constants';
import {UnsubscribableComponent} from '../shared/classes/abstract/unsubscribable-component';
import {Store} from '@ngrx/store';
import {State} from '../store/app.state';
import {User} from '../shared/interfaces/user';
import {LayoutService} from '../shared/services/layout.service';
import {MatSidenavContainer} from '@angular/material/sidenav';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends UnsubscribableComponent implements OnInit, OnDestroy {

  @ViewChild('matSidenavContainer') sidenavContainer: MatSidenavContainer;

  public authUser: User | null;
  public roles = APP_USER_ROLES;

  constructor(
    private store: Store<State>,
    public router: Router,
    public layoutService: LayoutService
  ) {
    super();
    // this.router.events.subscribe((event) => {
      // console.log('123', event)
      // if (event instanceof NavigationEnd) {
      //   console.log('this.router.events.subscribe((event) => {', event)
      // }
    // });
    this.sub = this.layoutService.sidenavOpened.pipe(debounceTime(300)).subscribe(() => this.sidenavContainer?.updateContentMargins());
  }

  ngOnInit(): void {
    this.sub = this.store.select(selectAppUser).subscribe(user => this.authUser = user !== APP_USER_STATUS.NOT_AUTHORIZED ? user : null);
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }
}
