import {Subscription} from 'rxjs';

export class UnsubscribableComponent {
  protected subscriptions: Subscription[] = [];

  protected set sub(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  protected unsubscribeAll() {
    this.subscriptions.forEach(item => item.unsubscribe());
  }
}
