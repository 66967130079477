import { UrlHandlingStrategy, UrlTree } from '@angular/router';
import { RouterHelper } from '../helpers/router-helper';
import { Injectable } from '@angular/core';

@Injectable()
export class PayquadRouteStrategy implements UrlHandlingStrategy {

  shouldProcessUrl(url: UrlTree): boolean {
    console.log('shouldProcessUrl', url.toString(), url.toString() === '/' || RouterHelper.isAngular2Url(url));
    return url.toString() === '/' || RouterHelper.isAngular2Url(url);
  }

  extract(url: UrlTree): UrlTree {
    console.log('extract', url.toString())
    return url;
  }

  merge(newUrlPart: UrlTree, rawUrl: UrlTree): UrlTree {
    console.log('newUrlPart', newUrlPart.toString())
    console.log('rawUrl', rawUrl.toString())
    return newUrlPart;
  }
}
