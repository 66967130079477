import {Component, Input, OnInit} from '@angular/core';
import {UnsubscribableComponent} from '../../shared/classes/abstract/unsubscribable-component';
import {Observable, of} from 'rxjs';
import {User} from '../../shared/interfaces/user';
import {Router} from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/app.state';
import { setCurrentPage } from 'src/app/store/app.actions';
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";

export class MenuItem {
  private pChild: Array<MenuItem> = [];
  expanded = false;
  set child(value: Array<MenuItem>) {
    value.forEach(v => v.parent = this);
    this.pChild = value;
  }
  get child() {
    return this.pChild;
  }
  constructor(
    public name: string,
    public display_name: string,
    public icon = '',
    public routerLink?: string,
    child: MenuItem[] = [],
    public parent?: MenuItem,
  ) {
    this.child = child;
  }
}

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent extends UnsubscribableComponent implements OnInit {

  @Input()
  public authUser: User | null;

  public isCordova = Boolean(window.cordova);

  private menuItems = [
    new MenuItem('Companies',      this.translate.instant('Companies'),     'modern-building-4', '/#/n/companies'),
    new MenuItem('Properties',     this.translate.instant('Properties'),    'modern-building-4', '/#/properties'),
    new MenuItem('Residents',      this.translate.instant('Residents'),     'home',              '/#/tenants/manage'),
    new MenuItem('Payments',       this.translate.instant('Payments'),      'wallet',            '/#/payments'),
    new MenuItem('Managers',       this.translate.instant('Managers'),      'edit',              '/#/managers'),
    new MenuItem('Communication',  this.translate.instant('Communication'), 'headphones',        '/#/messages'),
    new MenuItem('Maintenance',    this.translate.instant('Maintenance'),   'wrench',            '/#/maintenance-requests'),
    new MenuItem('Reports',        this.translate.instant('Reports'),       'copy',              '/#/reports'),
    new MenuItem('Documents',      this.translate.instant('Documents'),     'docs',              '/#/n/documents/list/regular'),
    new MenuItem('Amenities',      this.translate.instant('Amenities'),     'access',            '/#/amenity/bookings'),
    new MenuItem('Surveys',        this.translate.instant('Surveys'),       'edit',              '/#/n/surveys/list'),
    new MenuItem('Lease',          this.translate.instant('Lease'),         'house-star',        '/#/lease-application/list'),
    new MenuItem('Parcels',        this.translate.instant('Parcels'),       'box',               '/#/n/parcels/list'),
    new MenuItem('Inspections',    this.translate.instant('Inspections'),   'change',            '/#/n/inspections/list'),
  ]

  public menu: Observable<MenuItem[]> = of(this.menuItems);

  constructor(
    public router: Router,
    private store: Store<State>,
    private translate: TranslateService,
    private http: HttpClient,
  ) {
    super();
  }

  ngOnInit() {
    if (this.authUser.has_angular_messaging_access) {
        this.menu = of(this.replaceMenuItem(this.menuItems, 'Communication', new MenuItem('Communication', this.translate.instant('Communication'), 'headphones', '/#/n/messages/inbox/tenants')));
    }
  }

  link(item: MenuItem) {
    // if(item.routerLink.includes('/n/')) {
    //   this.store.dispatch(setCurrentPage({currentPage: item.display_name}));
    //   this.router.navigate([item.routerLink]); // usually router.navigate is safer, but causing the component constructor to be called twice
    // }else{
      window.location.href = item.routerLink;
    // }

    if (item.child.length) {
      item.expanded = !item.expanded;
    }
  }

  checkAccess(item: MenuItem): boolean {
    switch (item.name) {
      case 'Companies':
        return this.authUser.permissions === 'payquad_admin';
      case 'Properties':
        return this.authUser.permissions !== 'payquad_admin' && this.authUser.user_permissions.properties;
      case 'Residents':
        return this.authUser.user_permissions.residents;
      case 'Payments':
        return this.authUser.user_permissions.payments;
      case 'Managers':
        return this.authUser.permissions !== 'junior_manager' && this.authUser.permissions !== 'applicant';
      case 'Communication':
        return this.authUser.user_permissions.communications;
      case 'Maintenance':
        return this.authUser.user_permissions['maintenance-requests'];
      case 'Reports':
        return this.authUser.user_permissions.reports;
      case 'Documents':
        return this.authUser.user_permissions.documents;
      case 'Amenities':
        return this.authUser.user_permissions.amenities;
      case 'Surveys':
        return this.authUser.user_permissions.surveys;
      case 'Lease':
        return this.authUser.user_permissions['lease-applications'];
      case 'Parcels':
        return this.authUser.user_permissions.parcels;
      case 'Inspections':
        return this.authUser.user_permissions['inspections'];
    }
  }

  openSupport() {
    if (this.isCordova) {
      window.cordova.InAppBrowser.open('https://payquad.com/2342fdsa32/', '_system');
    }
  }

  replaceMenuItem(menuItems: MenuItem[], oldTitle: string, newMenuItem: MenuItem): MenuItem[] {
    return menuItems.map(item => item.name === oldTitle ? newMenuItem : item);
  }
}
