import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class OptInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return next.handle(req).pipe(
      catchError(error => this.handleError(error))
    );
  }

  private handleError(error: HttpErrorResponse): any {
    console.log('req0', error.error.message)

    if (error.error.message === 'OPT_REQUIRED') {
      this.router.navigate(['/n/2fa']);
    } else if (error.error.message === 'OPT_INSTALLATION_REQUIRED') {
      // window.location.href = '/#/account';
    } else {
      return throwError(error);
    }
  }
}
