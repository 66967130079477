import {
  Component,
  Input,
  IterableChanges,
  IterableDiffer,
  IterableDiffers,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {UnsubscribableComponent} from '../../classes/abstract/unsubscribable-component';
import { SelectOption } from '../../interfaces/select-option';

/**
 * Reusable Single filter for filter bar in index pages
 */
@Component({
  selector: 'table-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss']
})
export class TableSingleSelectComponent extends UnsubscribableComponent implements OnInit, OnDestroy {
  @Input() control: UntypedFormControl;
  @Input() options: any[];
  @Input() label: string;
  @Input() transpiler: (item: any) => SelectOption;

  transpilingOptions: Array<SelectOption> = [];
  private _diff: IterableDiffer<number>;

  constructor(
    private _iterableDiffers: IterableDiffers,
  ) {
    super();
  }

  ngOnInit() {
    this._diff = this._iterableDiffers.find(this.options).create();
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  // populate options
  ngDoCheck(): void {
    const changes: IterableChanges<number> = this._diff.diff(this.options);

    if (changes) {
      // console.log("options", this.options)
      // console.log("transpiler", this.transpiler)
      this.transpilingOptions = this.options.map(this.transpiler);
    }
  }

  // Consumed by the filter trigger
  getSelectedOptionLabel(value: any): string {
    if (value) {
      return this.transpilingOptions.find(
        o => o.value.toString() === value.toString()
      )?.label;
    }
    return '';
  }
}
