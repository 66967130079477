import {
  AfterViewInit,
  ContentChildren,
  Directive,
  HostBinding,
  Input,
  QueryList,
} from "@angular/core";
import { LoadableDirective } from "./loadable.directive";

@Directive({
  selector: "[loadableHost]",
})
export class LoadableHostDirective implements AfterViewInit {
  @HostBinding("class") elementClass = "loading";

  private _loaded = false;
  @Input() set loaded(resolved: boolean | null) {
    this.elementClass = resolved ? "" : "loading";
  }

  get loaded(): boolean {
    return this._loaded;
  }

  @ContentChildren(LoadableDirective, { descendants: true })
  contentChildren!: QueryList<LoadableDirective>;

  private _loadableSections?: { [sectionName: string]: any };

  @Input() set loadableSections(sections: { [sectionName: string]: any } | undefined) {
    this._loadableSections = sections;
  }

  get loadableSections(): { [sectionName: string]: any } | undefined {
    return this._loadableSections;
  }

  constructor() {}

  ngAfterViewInit(): void {}
}
