<mat-form-field
  appearance="outline"
  [ngClass]="{'mat-table-form-field-inline-text': true}"
>
  <div class="select-container">
    <ng-container *ngIf="!control.value.length">
      <span class="trigger">{{label}} Filter</span>
    </ng-container>
    <div [ngClass]="{'empty-height': !control.value.length}">
      <mat-select [formControl]="control">
        <mat-select-trigger>
          <span class="trigger">{{getSelectedOptionLabel(control.value) || ''}}</span>
          <mat-icon svgIcon="pq:chevron-down"></mat-icon>
        </mat-select-trigger>
        <mat-option *ngFor="let option of transpilingOptions" [value]="option.value">
          <span class="option-label">{{option.label}}</span>
        </mat-option>
      </mat-select>
    </div>
  </div>
</mat-form-field>