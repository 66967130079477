import {Action, createReducer, on} from '@ngrx/store';
import {AppState, initialAppState} from './app.state';
import * as actions from './app.actions';

const _reducer = createReducer(
  initialAppState,
  on(actions.setUser, (state, {user}) => ({...state, user})),
  on(actions.setBranding, (state, {branding}) => ({...state, branding})),
  on(actions.setTranslations, (state, {translations}) => ({...state, translations})),
  on(actions.setLocale, (state, { locale }) => ({ ...state, locale: locale || 'en' })),
  on(actions.setCurrentPage, (state, {currentPage}) => ({...state, currentPage})),
);

export function appReducer(state: AppState | undefined, action: Action) {
  return _reducer(state, action);
}
