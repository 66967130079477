import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {ApiResponse} from './shared/interfaces/api-response';
import {User} from './shared/interfaces/user';
import {from, Observable, of} from 'rxjs';
import {Branding} from './shared/interfaces/branding';
import { environment } from '../environments/environment'
import {Network} from "@capacitor/network";
import {switchMap, tap} from "rxjs/operators";
import {Preferences} from "@capacitor/preferences";

interface GetTranslationResponse {
  translations: object,
  current_locale: 'en' | 'fr'
}

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private http: HttpClient) {
  }

  public loadUser(): Observable<ApiResponse<User>> {
    return from(Network.getStatus()).pipe(
      switchMap(status => {
        if (status.connected) {
          return this.http.get<ApiResponse<User>>(`${environment.domain}/api/v1/users/get-auth?new=1`).pipe(
            tap(response => {
              Preferences.set({
                key: 'cachedUser',
                value: JSON.stringify(response)
              });
            })
          );
        } else {
          return from(Preferences.get({ key: 'cachedUser' })).pipe(
            switchMap(cachedData => {
              if (cachedData.value) {
                return of(JSON.parse(cachedData.value));
              } else {
                return of(null);
              }
            })
          );
        }
      })
    );
  }

  public patchUser(update): Observable<ApiResponse<User>> {
    return this.http.patch<ApiResponse<User>>(`${environment.domain}/api/v1/account`, update);
  }

  public changeUser(id: number): Observable<ApiResponse<User>> {
    return this.http.post<ApiResponse<User>>(`${environment.domain}/api/v1/users/change-account`, {id});
  }

  public loadTranslations(locale = 'default'): Observable<GetTranslationResponse> {
    return from(Network.getStatus()).pipe(
      switchMap(status => {
        if (status.connected) {
          return this.http.get<GetTranslationResponse>(`${environment.domain}/api/v1/get-translations/${locale}`).pipe(
            tap(response => {
              Preferences.set({
                key: `cachedTranslations-${locale}`,
                value: JSON.stringify(response)
              });
            })
          );
        } else {
          return from(Preferences.get({ key: `cachedTranslations-${locale}` })).pipe(
            switchMap(cachedData => {
              if (cachedData.value) {
                return of(JSON.parse(cachedData.value));
              } else {
                return of(null);
              }
            })
          );
        }
      })
    );
  }

  public signOut(): Observable<any> {
    return this.http.post(`${environment.domain}/api/v1/users/logout`, {});
  }

  public loadBranding(applicationId = null, propertyId = null): Observable<ApiResponse<Branding>> {
    const params = new HttpParams().set('cordova', window['cordova'] ? 1 : 0);

    if (applicationId) {
      params.set('application_id', applicationId);
    }
    if (propertyId) {
      params.set('property_id', propertyId);
    }

    return from(Network.getStatus()).pipe(
      switchMap(status => {
        if (status.connected) {
          return this.http.get<ApiResponse<Branding>>(`${environment.domain}/api/v1/branding`, { params: params }).pipe(
            tap(response => {
              Preferences.set({
                key: 'cachedBranding',
                value: JSON.stringify(response)
              });
            })
          );
        } else {
          return from(Preferences.get({ key: 'cachedBranding' })).pipe(
            switchMap(cachedData => {
              if (cachedData.value) {
                return of(JSON.parse(cachedData.value));
              } else {
                return of(null);
              }
            })
          );
        }
      })
    );
  }
}
