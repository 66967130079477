import { animate, group, state, style, transition, trigger } from '@angular/animations';

export const slideDownAnimation = [
  trigger('slideDownAnimation', [
    state('open', style({height: '*', opacity: 1})),
    state('closed', style({height: '0', opacity: 0})),
    transition('open => closed', [
      style({height: '*', opacity: 1}),
      group([
        animate(200, style({height: 0})),
        animate('200ms ease-in-out', style({'opacity': '0'}))
      ])
    ]),
    transition('closed => open', [
      style({height: '0', opacity: 0}),
      group([
        animate(200, style({height: '*'})),
        animate('400ms ease-in-out', style({'opacity': '1'}))
      ])
    ])
  ])
];
